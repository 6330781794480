<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name']"
          prefix="projectsList"
          @searchInput="searchInput"
          ref="searchComponent"
      />
  
      <v-card class="mt-5 pb-6 rounded-xl pa-6">
        <v-btn
          fab
          dark
          small
          color="primary"
          @click="showCreate"
          class="btn-add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-data-table
          :headers="tableHeaders"
          :items="projects"
          :loading="!loading"
          :loading-text="$t('multilang.load')"
          item-key="id"
          density="compact"
          :items-per-page="tableItemsPerPage"
          :footer-props="tableFooterProps"
          class="mt-4 table"
          :sort-by="sortTableBy"
          :sort-desc="sortTableDesc"
          :custom-sort="customSort"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.id }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.name }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.name)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.instanceId`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">
                {{ showInstance(item.instanceId) }}
              </span>
              <v-icon class="copy" @click.prevent="copyData(showInstance(item.instanceId))">mdi-content-copy</v-icon>
            </div>
          </template>

          <template v-slot:[`item.langs`]="{ item }">
          <div
            v-if="item.langs?.length"
            @click="enterSelect(item.id)"
            class="table-item"
            style="min-width: 80px;"
          >
            <p>{{ showLangs(item.langs) }}</p>
            <v-icon
              @click.prevent="copyData(item.langs)"
              class="copy"
            >mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
          <template v-slot:[`item.properties`]="{ item }">
          <div
              v-if="item.properties?.length"
              class="table-item"
          >
            <pre @click="enterSelect(item.id)">{{ item.properties }}</pre>
            <v-icon
              @click.prevent="copyData(item.properties)"
              class="copy"
            >mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col id="right-col" cols="3" style="position: relative;" v-if="visibleEditor">
      <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
        <div style="position: fixed;">
          <v-btn
            class="btn-close"
            icon
            color="red"
            @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
          <p v-if="newData.id" class="mr-6"> Id:
            <b style="color:#9c9c9c;">{{ newData.id }}</b>
            <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
          </p>
          <v-text-field
            :label="$t('name')"
            hide-details="auto"
            outlined
            v-model="newData.name"
            require
          ></v-text-field>
          <v-autocomplete
            :items="instances"
            v-model="newData.instanceId"
            item-value="id"
            item-text="name"
            outlined
            :placeholder="$t('projectList.instance')"
            hide-details
            class="mt-1"
          />

          <template v-if="newData.id">
            <v-text-field
              :label="$t('langs')"
              hide-details="auto"
              outlined
              readonly
              :value="showLangs(newData.langs)"
            ></v-text-field>

            <div class="json-editor">
              <span>{{ $t('projectList.properties') }}</span>
              <JsonEditorVue
                v-model="newData.properties"
                v-bind="JsonEditorSetting"
                class="mb-3"
                style="position: relative;"
                ref="jsonEditor-properties"
              />
              <v-icon class="copy_json" @click="copyData(newData.properties)">mdi-content-copy</v-icon>
            </div>

            <div class="">
              <span>{{ $t('projectList.connectToProcess') }}</span>
              <div class="d-flex align-center" style="gap:5px;">
                <v-autocomplete
                  :items="processes"
                  v-model="newData.process"
                  item-text="process_name"
                  outlined
                  :placeholder="$t('projectList.processes')"
                  hide-details
                  class="mt-1"
                  return-object
                />
                <v-btn 
                  v-if="newData.process?.process_name"
                  icon 
                  @click="deleteConnectionProcess"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </template>

          <div class="editor-col__btns">
            <template v-if="newData.id">
              <v-btn :disabled="requireFields" @click="updateProject">{{ $t('update') }}</v-btn>
              <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
              <v-btn @click="deleteProject(newData.id)">{{ $t('delete') }}</v-btn>
            </template>
            <template v-else>
              <v-btn :disabled="requireFields" @click="createProject">{{ $t('create') }}</v-btn>
            </template>
          </div>
        </div>
        <SpinnerLoader 
          v-else
          color="blue" 
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{
  name: "ProjectsList",

  data(){
    return{
      templateData: {},

      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('projectList.instance'), value: 'instanceId'},
        {text: this.$t('langs'), value: 'langs'},
        {text: this.$t('projectList.properties'), value: 'properties'}
      ],
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
        mainMenuBar: false,
        readOnly: true,
      },
      trackerProcess: {},
    }
  },
  mixins: [CRUD_UI_Mixin],
  computed: {
    filteredData(){
      return this.$store.getters['projectsList/searchedProjects']
    },
    projects(){
      const projects = this.$store.getters['projectsList/projects']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? projects
          : this.filteredData
    },
    loading(){
      return this.projects && this.projects.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['projectsList/loadedAllData']
    }, 
    instances(){
      return this.$store.getters['projectsList/instances']
    },
    processes(){
      return this.$store.getters['processes/processes']
    },
    requireFields(){
      return (!this.newData.name || !this.newData.instanceId)
    }
  },
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('projectsList/getDataById', {id, show: true})
      this.parseProperties(data, 'properties')

      this.newData = data
    },

    loadData(){
      this.$store.dispatch('projectsList/getData')
      this.$store.dispatch('projectsList/getInstance')

      this.$store.dispatch('processes/getProcesses')
    },

    async createProject(){
      this.parseProperties(this.newData, 'properties')
      const newId = await this.$store.dispatch('projectsList/createProject', {newData: this.newData, searchData: this.search})

      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    duplicate(id){
      const projects = this.projects.find(field => field.id === id)

      const project = JSON.parse(JSON.stringify(projects))
      delete project.id

      this.visibleCopy = true
      this.newData = project
    },

    async deleteProject(id){
      await this.$store.dispatch('projectsList/deleteProcess', {id: this.newData.id, processId: this.trackerProcess.id})
      this.$store.dispatch('projectsList/deleteProject', {id, searchData: this.search})

      this.closeEditor()
    },

    async updateProject(){
      const isNewProcess = !this.trackerProcess?.id
      const isSameProcess = this.trackerProcess?.id === this.newData?.process?.id

      if (this.newData?.process?.id) {
        if (isSameProcess) {
          // nothing change
        } else if (isNewProcess) {
          // add process
          await this.$store.dispatch('projectsList/saveProcess', {id: this.newData.id, processId: this.newData.process.id})
        } else {
          // change process
          await this.$store.dispatch('projectsList/deleteProcess', {id: this.newData.id, processId: this.trackerProcess.id})
          await this.$store.dispatch('projectsList/saveProcess', {id: this.newData.id, processId: this.newData.process.id})
        }
      } else {
        if (!isNewProcess) {
          // delete process
          await this.$store.dispatch('projectsList/deleteProcess', {id: this.newData.id, processId: this.trackerProcess.id})
        }
      }

      this.parseProperties(this.newData, 'properties')

      this.trackerProcess = this.newData?.process
        ? JSON.parse(JSON.stringify(this.newData.process))
        : {}

      this.$store.dispatch('projectsList/updateProject',
          {data: JSON.parse(JSON.stringify(this.newData)), searchData: this.search}
      )
    },
    showInstance(id){
      const instance = this.instances.find(instance => instance.id === id)
      return `${id}: ${instance?.name}`
    },
    showLangs(langs){
      return langs?.map(lang => lang.lang_name)?.join(', ') ?? ''
    },
    async getProcess(id){
      const process = await this.$store.dispatch('projectsList/getProcess', id)

      if(process.process_name) this.$set(this.newData, 'process', process.process_name)
    },
    deleteConnectionProcess(){
      this.$set(this.newData, 'process', {})
    }
  },
  created(){
    this.loadData()
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/CRUD.scss";
</style>