import {projectsService} from "@/services/projectsList.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const projectsList = {
    namespaced: true,
    state: {
      projects: [],
      searchedProjects: [],
      loadedAllData: false,
      instances: []
    },
    getters: {
      projects: state => state.projects,
      searchedProjects: state => state.searchedProjects,
      loadedAllData: state => state.loadedAllData,
      instances: state => state.instances
    },
    mutations: {
      setProjects(state, value) {
        state.projects = [...state.projects, ...value.projects]
      },
      setSearchedProjects(state, value){
          state.searchedProjects = state.searchedProjects?.length
              ? [...state.searchedProjects, ...value]
              : value
      },
      deleteProject(state, {id, searchData}){
        state.projects = state.projects.filter(field => field.id !== id)
        if(Object.values(searchData).some(item => item)) state.searchedProjects = state.searchedProjects.filter(field => field.id !== id)
      },
      addProject(state, {dataProject, searchData}){
        state.projects.push(dataProject)
        if(Object.values(searchData).some(item => item)) {
          state.searchedProjects?.length
              ? state.searchedProjects.push(dataProject)
              : state.searchedProjects = [dataProject]
        }
      },
      cleanSearchedData(state){
          state.searchedProjects = null
      },
      cleanProjects(state){
          state.projects = []
      },
      setLoaded(state, loaded){
        state.loadedAllData = loaded
      },
      setInstance(state, instances){
        state.instances = instances.instances
      }
    },
    actions: {
      async getData({commit, getters, dispatch}, payload) {
        const response = await projectsService.getProjects({offset: payload?.offset || 0})

        if(getters.projects.length >= response.response.total - 1) commit('cleanProjects')
        commit('setProjects', response.response)

        const loaded = await requestWithOffset(response.response, payload, 'getData', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataFiltered({commit, dispatch}, searchData){
          const isType = false // need to hide type in request
          const keys = Object.keys(searchData)
          const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

          const data = {
              isType,
              type: availableKey,
              search: availableKey ? searchData[availableKey] : '',
              offset: searchData?.offset || 0
          }
          const response = await projectsService.getProjects(data)
        
        if(!response?.response?.projects?.length) {
          dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
          return commit('setLoaded', true)
        }
        commit('setSearchedProjects', response.response.projects)

        const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataById({commit, dispatch}, {id, show}) {
        const response = await projectsService.getProjectById(id)
        const process = await dispatch('getProcess', id)

        commit('setLoaded', true)
        if(!response?.response?.id) return dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
        if(!show) {
          commit('setSearchedProjects', [{...response.response, process}])
          commit('setLoaded', true)
        }

        return {...response.response, process}
      },

      loadData({commit}){
        commit('setLoaded', false)
      },

      cleanSearchedData({commit}){
          commit('cleanSearchedData')
      },

      async createProject({commit, dispatch}, {newData, searchData}){
          const response = await projectsService.createProject(newData)

          if(!response.response || response.status === 0) {
              dispatch('alert/error', i18n.t('projectList.alert.createError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('projectList.alert.createSuccess'), { root: true })

              const createdProject = await projectsService.getProjectById(response.response.id)
              commit('addProject', {dataProject: createdProject.response, searchData})

              // re-creation
              searchData
                  ? dispatch('getDataFiltered', searchData)
                  : dispatch('getData')

              return response.response.id
          }
      },
     
      async updateProject({commit, dispatch}, {data, searchData}){
          const updateData = {name: data.name, instanceId: data.instanceId, id: data.id}
          const response = await projectsService.updateProject(updateData)

          if(!response?.response || response.status === 0){
              dispatch('alert/error', i18n.t('projectList.alert.updateError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('projectList.alert.updateSuccess'), { root: true })

              commit('deleteProject', {id: data.id, searchData})
              commit('addProject', {dataProject: data, searchData})
          }
      },
      async deleteProject({commit, dispatch}, {id, searchData}){
          const response = await projectsService.deleteProject(id)

          if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
              dispatch('alert/error', i18n.t('projectList.alert.deleteError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('projectList.alert.deleteSuccess'), { root: true })

              searchData?.id
                  ? dispatch('cleanSearchedData')
                  : commit('deleteProject', {id, searchData})
          }
      },
      async getProcess({commit}, id){
        const response = await projectsService.getProcess(id)

        if(!response?.response || response.status === 0) return 
        return response.response[0]
      },
      
      async saveProcess({commit, dispatch}, {id, processId}){
        const response = await projectsService.saveProcess({id, processId})

        if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
          dispatch('alert/error', i18n.t('projectList.alert.saveProcessError'), { root: true })
        }else{
            dispatch('alert/success', i18n.t('projectList.alert.saveProcessSuccess'), { root: true })
        }
      },
      
      async deleteProcess({commit, dispatch}, {id, processId}){
        const response = await projectsService.deleteProcess({id, processId})

        if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
          dispatch('alert/error', i18n.t('projectList.alert.deleteProcessError'), { root: true })
        }else{
            dispatch('alert/success', i18n.t('projectList.alert.deleteProcessSuccess'), { root: true })
        }
      },

      async getInstance({commit, dispatch}, payload){
        const response = await projectsService.getInstance(payload?.offset || 0)
        await requestWithOffset(response.response, null, 'getInstance', dispatch)

        commit('setInstance', response.response)
      },
    }
}